<template>
  <ul class="clebex-item-section">
    <li
      class="clebex-item-section-item full-right-underline"
      :class="{ 'hidden-underline': !pickerActive }"
      @click="pickerActive = !pickerActive"
    >
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{
            displayLabelName("preferences", "set-preferences", "choose-color")
          }}</label>
        </div>
        <span class="follow-up-icons">
          <div
            class="choose-color"
            :style="{ backgroundColor: newValue }"
          ></div>
        </span>
      </div>
    </li>
    <li
      class="clebex-item-section-item full-right-underline"
      :class="{ hidden: !pickerActive }"
    >
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <ColorPicker
            theme="light"
            :color="newValue"
            :sucker-hide="true"
            @changeColor="setData"
          ></ColorPicker>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ColorPicker } from "vue-color-kit";
import "vue-color-kit/dist/vue-color-kit.css";

export default {
  name: "AverageOccupancyColor",
  components: {
    ColorPicker: ColorPicker
  },
  computed: {
    ...mapState("companyPreferences", ["preferences", "preferenceUpdateData"])
  },
  data() {
    return {
      newValue: null,
      pickerActive: false
    };
  },
  created() {
    this.newValue = this.preferenceUpdateData.values[0].value;
  },
  props: {
    preferenceId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions("companyPreferences", ["setPreferenceUpdateData"]),
    setData(color) {
      this.newValue = color.hex;
      const newPreferenceUpdateData = {
        values: [
          {
            value: this.newValue
          }
        ]
      };
      if (this.preferenceUpdateData.values[0].id) {
        newPreferenceUpdateData.values[0].id = this.preferenceUpdateData.values[0].id;
      }
      this.setPreferenceUpdateData(newPreferenceUpdateData);
    }
  }
};
</script>
<style lang="scss" scoped>
.clebex-section-input :deep() {
  .colors,
  .color-type,
  .color-show {
    display: none !important;
  }
  .hu-color-picker {
    margin-left: 75px;
    box-shadow: none;
    &.light {
      background-color: $white;
    }
  }
}
</style>
